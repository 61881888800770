
import { defineComponent, PropType } from "vue";
import { additionalCost, jobProjection } from "@/components/jobs/Misc/types";
import DeleteButton from "@/components/Icons/DeleteButton.vue";
import PlusSign from "@/components/Icons/PlusSign.vue";
import IconCheck from "@/components/Icons/IconCheck.vue";
import Swal from "sweetalert2";
import {useStore} from "vuex";

export default defineComponent({
  name: 'job-projection-table',
  components:{
    PlusSign,
    DeleteButton,
    IconCheck,
  },
  props: {
    clientInvoice: {
      type: [Number, String] as PropType<number|string>,
      required: true
    },
    workerSalaries: {
      type: [Number, String] as PropType<number|string>,
      required: true
    },
    realClientInvoice: {
      type: [Number, String] as PropType<number|string>,
      required: true
    },
    clientCurrency: {
      type: Object,
      required: false,
    },
    additionalCosts: {
      type: Array as PropType<additionalCost[]>,
      default: () => []
    },
    isSaving: {
      type: Array as PropType<(string|number)[]>,
      default: () => []
    }
  },
})
