
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: 'totals-tab',
  props: {
    total: {
      type: [Number, String] as PropType<number|string>,
      default: () => 0
    },
    clientCurrency: {
      type: Object,
      required: false
    }
  }
})
