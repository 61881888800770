
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import {useRoute, useRouter} from "vue-router";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import JobProjectionTable from "@/components/jobs/Misc/JobProjectionTable.vue";
import JobProjectionTotals from "@/components/jobs/Misc/JobProjectionTotals.vue";
import MissingRatesTooltip from "@/components/jobs/Misc/MissingRatesTooltip.vue";
import {additionalCost, initCost, jobProjection, projectionTotals} from "@/components/jobs/Misc/types";
import {useAbility} from "@casl/vue";
import {fireErrorSwal, fireSwal} from "@/components/jobs/Misc/helpers";
import * as helpers from "@/components/jobs/Misc/helpers";
import _ from 'lodash';
import Swal from "sweetalert2";

export default defineComponent({
  name: 'job-projection-tab',
  components: {
    MissingRatesTooltip,
    LoadingSpinner,
    JobProjectionTable,
    JobProjectionTotals
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const ability = useAbility();
    const loading = ref<boolean>(false);
    const isSaving = ref<(string | number)[]>([]);
    const job = computed(() => store.getters['JobModule/item']);
    const jobProjection = computed<jobProjection>(() => store.getters['AdditionalCostsModule/getJobProjection']);
    const clientCurrency = computed(() => store.getters['AdditionalCostsModule/getClientCurrency']);
    const additionalCostsState = ref<additionalCost[]>([]);
    const fetchClientCurrency = () => {
      const company = job.value.company ? job.value.company.id : null;
      const branch = job.value.book_from_branches.length > 0 ? job.value.book_from_branches[0].id : null;
      if(company && branch) {
        store.dispatch('AdditionalCostsModule/fetchClientCurrency', {company: company, branch: branch})
      }
    }
    onMounted(() => {
      const jobID = route.params.id;
      if(jobID) {
        loading.value = true;
        store.dispatch("AdditionalCostsModule/fetchJobProjection", jobID)
            .then(() => {
              additionalCostsState.value = _.cloneDeep(jobProjection.value.additionalCosts);
              loading.value = false
            })
        fetchClientCurrency();
      }
    });
    const totals = computed<projectionTotals | null>(() => {
      let additionalCosts = 0 as number;
      if(additionalCostsState.value.length > 0) {
        const costs = additionalCostsState.value.map(cost => cost.amount) as (number | string)[];
        for(let i = 0; i < costs.length; i++) {
          if(typeof costs[i] == 'number') {
            additionalCosts += costs[i] as number;
          } else{
            additionalCosts += parseFloat(costs[i] as string);
          }
        }
      }
      console.log(jobProjection.value.estimated,jobProjection.value.current, additionalCosts);
      return {
        estimated: jobProjection.value.clientInvoice - (jobProjection.value.estimated + (additionalCosts ? additionalCosts : 0)),
        current: jobProjection.value.realClientInvoice - (jobProjection.value.current + (additionalCosts ? additionalCosts : 0))
      }
    })

    const handleAddNewCost = (): void => {
      additionalCostsState.value.push(initCost(job.value.id));
    }

    const handleDeleteNewCost = (id: string | number): void => {
     additionalCostsState.value.splice(additionalCostsState.value.findIndex(item => item.id == id), 1);
    }

    const handleUpdateUI = (data: additionalCost[], id: string|number) => {
      const unsavedCosts = additionalCostsState.value.filter(
          item => item.id !== id && typeof item.id == 'string'
      );
      additionalCostsState.value = data.concat(unsavedCosts);
    }

    const handleSaveCosts = (cost: additionalCost) => {
      isSaving.value.push(cost.id);
      const costs = _.cloneDeep(jobProjection.value.additionalCosts);
      costs.push(cost);
      const query = {
        jobID: job.value.id,
        costs: costs
      };
      store.dispatch('AdditionalCostsModule/saveAdditionalCosts', query)
          .then(response => {
            fireSwal();
            handleUpdateUI(response.data.data, cost.id);
          })
          .catch(() => {
            fireErrorSwal();
          })
          .finally(() => {
            isSaving.value.splice(isSaving.value.findIndex(id => id == cost.id), 1);
          })
    }
    const handleDeleteAdditionalCost = (cost: additionalCost) => {
      Swal.fire({
        title: `Do you want to delete ${cost.name} ?`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return store.dispatch("AdditionalCostsModule/deleteAdditionalCost", cost)
            .then(() => {
              additionalCostsState.value = _.cloneDeep(jobProjection.value.additionalCosts);
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          helpers.fireSuccessfulDeleteToast()
        }
      })
    }
    function formatToDecimal(number) {
      if (isNaN(number)) {
        return "";
      }
      return Number(number).toFixed(2);
    }

    return{
      totals,
      loading,
      formatToDecimal,
      handleAddNewCost,
      handleDeleteNewCost,
      handleSaveCosts,
      additionalCostsState,
      handleDeleteAdditionalCost,
      jobProjection,
      clientCurrency,
      isSaving
    }
  }
})
