import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "svg-icon svg-icon-warning svg-icon-2x" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.tooltipContent,
    placement: "top"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_InfoIcon)
      ])
    ]),
    _: 1
  }, 8, ["content"]))
}