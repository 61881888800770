
import { defineComponent } from "vue";
import InfoIcon from "@/components/Icons/Info-icon.vue";

export default defineComponent({
  name: 'missing-rates-tooltip',
  components: {
    InfoIcon
  },
  setup() {
    const tooltipContent = "There are missing rates that default to 0";
    return {
      tooltipContent
    }
  }
})
